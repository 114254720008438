.Widget {
  width: calc(100% - 1px);
  height: calc(100% - 1px);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0px;
  overflow: hidden;
}

.AppBar {
  -webkit-app-region: drag;
}

.NotDraggable {
  -webkit-app-region: no-drag
}

.CloseButton:hover{
  background-color: red important;
}

