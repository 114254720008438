.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
input,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 50px rgba(255, 255, 255, 0) inset !important;
  background-color: transparent !important;
  background-clip: text;
}

/* From https://css.glass */
.Glass {
  background: rgba(23, 23, 23, 0.22)!important;;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.6px);
  -webkit-backdrop-filter: blur(7.6px);
  border: 1px solid rgba(0, 0, 0, 0.1)!important;;

}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
}